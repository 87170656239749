import globalStyle from "../../../styles/globalStyle"

const normalDialogStyle = {
	dialogRoot: {
		height: 150
		// height: theme.spacing("var(--dialog-height)")
		// height: "var(--dialog-height)"
	},

	headerFooter: {
		backgroundColor: globalStyle.aquamarine[12],
		color: globalStyle.whiteColor,
		paddingLeft: 3,
		paddingRight: 3
	},

	searchBtn: {
		paddingLeft: 2,
		paddingRight: 2
	},


}


export default normalDialogStyle