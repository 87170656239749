import React, {Fragment, useState, useEffect} from "react"
import {Outlet, useNavigate, Link, useMatch, useResolvedPath} from "react-router-dom"

// Material UI
import {withStyles} from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'

// Material Icon
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import LogoutIcon from '@mui/icons-material/Logout'
import PasswordIcon from '@mui/icons-material/Password'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import ViewStreamIcon from '@mui/icons-material/ViewStream'

// Custom Icon
import Logo from "../../img/glorious-portal-logo.png"
import noticeBoardIcon from "../../img/icon-noticeboard.png"
import systemAppsIcon from "../../img/icon-systemapps.png"
import informationIcon from "../../img/icon-information.png"
import eformIcon from "../../img/icon-eform.png"
import knowHowForumIcon from "../../img/icon-knowhow.png"

// Custom Components
import ConfirmDialog from "../Dialog/ConfirmDialog"
import FullScreenDialog from "../Dialog/FullScreenDialog";
import ChangePassword from "../ChangePassword/ChangePassword";

// Custom Style
import mainStyle from './styles/mainStyle'

// Fetch Data



// Custom Typography
const UserNameTypography = withStyles({
	root: {
		"fontSize": 16,
		"fontWeight": 600,
	},
}) (Typography)


const Main = () => {

	// Define state
	const [anchorElAccount, setAnchorElAccount] = useState(null)
	const [anchorElHelp, setAnchorElHelp] = useState(null)
	const [anchorElMenuBar, setAnchorElMenuBar] = useState(null)

	const navigate = useNavigate()

	// Define Confirm Dialog
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [confirmDialogData, setConfirmDialogData] = useState({
		dialogTitle: "",
		dialogContent: "",
		confirmAction: "",
		cancelAction: ""
	})

	// Define sx
	const appBarButtonMenuPaperProps = {
		elevation: 0,
		sx: {
			overflow: 'visible',
			filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
			mt: 0.5,
			'& .MuiAvatar-root': {
				width: 32,
				height: 32,
				ml: -0.5,
				mr: 1,
			},
			'&:before': {
				content: '""',
				display: 'block',
				position: 'absolute',
				top: 0,
				right: 14,
				width: 10,
				height: 10,
				bgcolor: 'background.paper',
				transform: 'translateY(-50%) rotate(45deg)',
				zIndex: 0,
			},
		},
	}


  // Define ChangePassword Dialog
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);

  // Define open and close
  const changePasswordDialogHandleOpen = () => {
    setChangePasswordDialogOpen(true);
  };

  const changePasswordDialogHandleClose = () => {
    setChangePasswordDialogOpen(false);
  };

  //Action
  const changePasswordHandleClick = () => {
    changePasswordDialogHandleOpen();
  };


	// Custom Menu Button
	const CustomMenuButton = ({children, to, name, buttonImage, ...props}) => {
		let resolved = useResolvedPath(to)
		let match = useMatch({ path: resolved.pathname, end: true })
	
		return (
			<Button onClick={menuBtnAction(to)} startIcon={<img src={buttonImage} height="18" width="18"/>} sx={mainStyle.menuBarBtn}>
				<Box sx={{fontWeight: match ? 900 : "normal"}}>{name}</Box>
			</Button>
		)
	}

	// 
	const MenuButtonMenuItem = withStyles({
		root: {
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
	})(MenuItem)


	// Build Confirm dialog
	const confirmDialogHandleOpen = (data) => {
		setConfirmDialogData({
			dialogTitle: data.dialogTitle,
			dialogContent: data.dialogContent,
			confirmAction: data.confirmAction,
			cancelAction: data.cancelAction
		})

		setConfirmDialogOpen(true)
	}

	const confirmDialogHandleClose = () => {
		setConfirmDialogOpen(false)
	}


	const signoutConfirmHandleOpen = () => {
		confirmDialogHandleOpen({
			dialogTitle: "Logout",
			dialogContent: "Are you sure to Logout system.",
			confirmAction: signOutAction,
			cancelAction: signoutConfirmHandleClose
		})
	}

	const signoutConfirmHandleClose = () => {
		confirmDialogHandleClose()
	}


	const accountMenuHandleOpen = (event) => {
		setAnchorElAccount(event.currentTarget)
	}

	const accountMenuHandleClose = () => {
		setAnchorElAccount(null)
	}


	const helpMenuHandleOpen = (event) => {
		setAnchorElHelp(event.currentTarget)
	}

	const helpMenuHandleClose = () => {
		setAnchorElHelp(null)
	}


	const menuListHandleOpen = (event) => {
		setAnchorElMenuBar(event.currentTarget)
	}

	const menuListHandleClose = () => {
		setAnchorElMenuBar(null)
	}


	// Action
	const menuBtnAction = to => () => {
		if (to === "eform") {
			window.open(process.env.REACT_APP_EFORM_URL, '_blank')
		}
		else {
			navigate(to)
		}
	}


	const signOutAction = () => {
		signoutConfirmHandleClose()
		window.location.href = `${process.env.REACT_APP_OIDC_LOGOUT_URL}?redirect_uri=${process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI}`
	}


	useEffect(() => {
		console.log("Main.js")
		console.log("LOGGED_IN : ", localStorage.getItem("LOGGED_IN"))
		console.log("TOKEN_TYPE : ", localStorage.getItem("TOKEN_TYPE"))
		console.log("ACCESS_TOKEN : ", localStorage.getItem("ACCESS_TOKEN"))
		console.log("REFRESH_TOKEN : ", localStorage.getItem("REFRESH_TOKEN"))
		console.log("USER_SEQ : ", localStorage.getItem("USER_SEQ"))
		console.log("USERNAME : ", localStorage.getItem("USERNAME"))
		console.log("EMPLOYEE_ID : ", localStorage.getItem("EMPLOYEE_ID"))
		console.log("FULLNAME_ENG : ", localStorage.getItem("FULLNAME_ENG"))
	}, [])

	
	return (
		<Fragment>
			<Box>
				<ConfirmDialog
					open={confirmDialogOpen}
					dialogTitle={confirmDialogData.dialogTitle}
					dialogContent={confirmDialogData.dialogContent}
					confirmAction={confirmDialogData.confirmAction}
					cancelAction={confirmDialogData.cancelAction}
				/>

				<FullScreenDialog
					open={changePasswordDialogOpen}
					dialogTitle={"Change Password"}
					formContent={
						<ChangePassword
							changePasswordDialogHandleClose={changePasswordDialogHandleClose}
						/>
					}
					button={[]}
					handleClose={changePasswordDialogHandleClose}
				/>
			</Box>

			<Box style={mainStyle.root}>
				<AppBar position="fixed" sx={mainStyle.appBar}>
					<Toolbar>
						<Box sx={{flexGrow: 1}}>
							<span style={mainStyle.logo}>
								<img src={Logo} onClick={menuBtnAction("/")} />
							</span>
						</Box>
						
						<Stack
							direction="row"
							divider={<Divider orientation="vertical" flexItem color="white" />}
							spacing={1}
						>
							<Box>
								<Tooltip title='Help' aria-label='help'>
									<IconButton
										aria-label="help"
										aria-controls="help"
										aria-haspopup="true"
										onClick={helpMenuHandleOpen}
										sx={mainStyle.appBarIcon}
									>
										<HelpCenterIcon />
									</IconButton>
								</Tooltip>

								<Menu
									id="help-menu"
									anchorEl={anchorElHelp}
									open={Boolean(anchorElHelp)}
									onClose={helpMenuHandleClose}
									onClick={helpMenuHandleClose}
									PaperProps={appBarButtonMenuPaperProps}
									transformOrigin={{horizontal: 'right', vertical: 'top'}}
									anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
								>
									<MenuItem>
										<ListItemIcon>
											<MenuBookIcon
												fontSize="small" 
												sx={mainStyle.menuIcon}
											/>
										</ListItemIcon>
										User Manual
									</MenuItem>
								</Menu>
							</Box>

							<Box>
								<Tooltip title='User Account' aria-label='user-account'>
									<IconButton
										aria-label="user-account"
										aria-controls="user-account"
										aria-haspopup="true"
										onClick={accountMenuHandleOpen}
										sx={mainStyle.appBarIcon}
									>
										<AccountCircleIcon />

										<UserNameTypography sx={mainStyle.appBarUserFullName}>
											{localStorage.getItem("FULLNAME_ENG")}
										</UserNameTypography>
									</IconButton>	
								</Tooltip>

								<Menu
									id="user-account-menu"
									anchorEl={anchorElAccount}
									open={Boolean(anchorElAccount)}
									onClose={accountMenuHandleClose}
									onClick={accountMenuHandleClose}
									PaperProps={appBarButtonMenuPaperProps}
									transformOrigin={{horizontal: 'right', vertical: 'top'}}
									anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
								>
									<Paper  square sx={mainStyle.appBtnMenuUserFullName}>
										<UserNameTypography>
											{localStorage.getItem("FULLNAME_ENG")}
										</UserNameTypography>
									</Paper>

									{/*<MenuItem onClick={changePasswordHandleClick}>*/}
									{/*	<ListItemIcon>*/}
									{/*		<PasswordIcon */}
									{/*			fontSize="small"*/}
									{/*			sx={mainStyle.menuIcon}*/}
									{/*		/>*/}
									{/*	</ListItemIcon>*/}
									{/*	Change Password*/}
									{/*</MenuItem>*/}

									<MenuItem onClick={signoutConfirmHandleOpen}>
										<ListItemIcon >
											<LogoutIcon 
												fontSize="small"
												sx={mainStyle.menuIcon}
											/>
										</ListItemIcon>
										Logout
									</MenuItem>
								</Menu>
							</Box>
						</Stack>
					</Toolbar>
				</AppBar>


				<Box sx={mainStyle.menu}>
					<Paper elevation={2} square sx={mainStyle.menuBar}>
						<Box sx={{flexGrow: 1}}>
							<Stack direction="row" spacing={2}>
								<CustomMenuButton
									to={"noticeboard"}
									name={"Notice Board"}
									buttonImage={noticeBoardIcon}
								/>

								<CustomMenuButton
									to={"information"}
									name={"Information"}
									buttonImage={informationIcon}
								/>

								<CustomMenuButton
									to={"systemapps"}
									name={"System Apps"}
									buttonImage={systemAppsIcon}
								/>

								<CustomMenuButton
									to={"eform"}
									name={"eForm"}
									buttonImage={eformIcon}
								/>
							</Stack>
						</Box>

						<Box>
							<CustomMenuButton
								to={"knowhow"}
								name={"Know-How Forum"}
								buttonImage={knowHowForumIcon}
							/>
						</Box>
					</Paper>

					<Paper elevation={2} square sx={mainStyle.mobileMenuBar}>
						<Button onClick={menuListHandleOpen} startIcon={<ViewStreamIcon sx={mainStyle.menuIcon}/>} sx={mainStyle.menuBarBtn}>
							Menu
						</Button>

						<Menu
							id="menu-bar-menu"
							anchorEl={anchorElMenuBar}
							open={Boolean(anchorElMenuBar)}
							onClose={menuListHandleClose}
							onClick={menuListHandleClose}
						>
							<MenuButtonMenuItem>
								<CustomMenuButton
									to={"noticeboard"}
									name={"Notice Board"}
									buttonImage={noticeBoardIcon}
								/>
							</MenuButtonMenuItem>

							<MenuButtonMenuItem>
								<CustomMenuButton
									to={"information"}
									name={"Information"}
									buttonImage={informationIcon}
								/>
							</MenuButtonMenuItem>

							<MenuButtonMenuItem>
								<CustomMenuButton
									to={"systemapps"}
									name={"System Apps"}
									buttonImage={systemAppsIcon}
								/>
							</MenuButtonMenuItem>

							<MenuButtonMenuItem>
								<CustomMenuButton
									to={"eform"}
									name={"eForm"}
									buttonImage={eformIcon}
								/>
							</MenuButtonMenuItem>

							<MenuButtonMenuItem>
								<CustomMenuButton
									to={"knowhow"}
									name={"Know-How Forum"}
									buttonImage={knowHowForumIcon}
								/>
							</MenuButtonMenuItem>
						</Menu>
					</Paper>
				</Box>

				<Box sx={mainStyle.mainContent}>
					<Outlet />
				</Box>
			</Box>
		</Fragment>
	)
}

export default Main
