import React, {forwardRef} from "react"

// Material-UI Core
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from '@mui/material/Alert'
import AlertTitle from "@mui/material/AlertTitle"

// Custom Style
import alertStyle from './styles/alertStyle'


// Define Customer Alert
const CustomAlert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


const Alert = (props) => {
	const {open, alertType, alertTitle, alertContent, handleClose} = props

	return (
		<Snackbar
			open={open}
			anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
			autoHideDuration={5000}
			onClose={handleClose}
		>
			<CustomAlert severity={alertType} onClose={handleClose}>
				<AlertTitle>{alertTitle}</AlertTitle>
				{alertContent}
			</CustomAlert>
		</Snackbar>
	)
}


export default Alert